<template>
  <!-- Banner for Beta Preview -->
  <div class="sticky top-0 bg-gray-800 flex z-50 items-center">
    <div class="mx-auto">
      <p class="flex items-center text-xs text-white py-2">
        <EyeIcon class="h-4 w-4 mr-2" aria-hidden="true" />
        {{ title }}
      </p>
    </div>
  </div>
</template>

<script>
import {
  EyeIcon
} from '@heroicons/vue/outline';
export default {
  name: 'DashboardPreviewBanner',
  components: {
    EyeIcon
  },
  props: {
    title: String,
  }
};
</script>

<style lang="scss" scoped>

</style>